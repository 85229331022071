import React, { useState } from "react";
import logo from "../../assets/oba.png";
import Dropdown from "./dropdown";
import mobileLogo from "../../assets/mobileLogo.png";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate("/");
  };

  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 10) {
      setColor(true);
    } else {
      setColor(false);
    }
  };
  window.addEventListener("scroll", changeColor);

  return (
    <div
      className={
        color
          ? "bg-[#0E0C1D] text-[#babaff] fixed z-50 top-0 w-full tab:px-10 px-4 duration-300"
          : "bg-transparent font-medium text-[#babaff] fixed z-50 top-0 w-full tab:px-10 px-4 duration-300"
      }
    >
      <div className="max-w-[1480px] py-3 text-[14px] tab:text-[17px] max-w-screen-2xl mx-auto flex justify-between items-center">
        <img
          src={logo}
          alt="odd-planet"
          onClick={navigateHome}
          className="h-14 cursor-pointer tab:block hidden"
        />
        <img
          src={mobileLogo}
          alt="odd-planet"
          onClick={navigateHome}
          className="h-12 cursor-pointer tab:hidden"
        />
        <div className="flex items-center gap-4 tab:gap-8">
          {/* <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://presale.oddplanet.co/"
            className="hover:text-purple-500 duration-500 cursor-pointer"
          >
            Presale
          </a> */}
          <Link
            to="/token"
            className="hover:text-purple-500 duration-500 cursor-pointer"
          >
            Token
          </Link>
          <Link
            to="/blog"
            className="hover:text-purple-500 duration-500 cursor-pointer"
          >
            Blog
          </Link>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://medium.com/@oddplanet.official"
            className="hover:text-purple-500 duration-500 cursor-pointer"
          >
            Docs
          </a>

          <Dropdown />
        </div>
      </div>
    </div>
  );
};

export default Header;
