import React from "react";
import uniswap from "../../assets/exchanges/uniswap.png";
import binance from "../../assets/exchanges/binance.svg";
import coinbase from "../../assets/exchanges/coinbase.svg";
import kucoin from "../../assets/exchanges/kucoin.svg";
import oneinch from "../../assets/exchanges/1inch.png";
import gateio from "../../assets/exchanges/gateio.png";
import gemini from "../../assets/exchanges/gemini.png";
import cryptocom from "../../assets/exchanges/crypto.com.png";
import sushiswap from "../../assets/exchanges/sushiswap.png";
import kraken from "../../assets/exchanges/kraken.png";

const sup = [
  { id: 1, image: sushiswap },
  { id: 2, image: uniswap },
  { id: 3, image: oneinch },
  // { id: 4, image: binance },
  // { id: 5, image: coinbase },
  // { id: 6, image: kucoin },
  // { id: 7, image: cryptocom },
  // { id: 8, image: gateio },
  // { id: 9, image: kraken },
  // { id: 10, image: gemini },
];

const SupporterCards = () => {
  return (
    <div className="flex flex-wrap justify-center mx-auto items-center px-10 gap-20 pb-20 max-w-5xl">
      {sup.map((i) => (
        <img
          src={i.image}
          alt="supporters"
          className="w-14 h-14 tab:h-20  tab:w-20"
        />
      ))}
    </div>
  );
};

const Supporters = () => {
  return (
    <div className="">
      <p className=" text-[35px] shadowText text-center pb-14 pt-24">
        Coming Soon To
      </p>
      <SupporterCards />
    </div>
  );
};

export default Supporters;
