import React from "react";
import cover1 from "../../assets/blogCovers/cover1.jpg";
import cover2 from "../../assets/blogCovers/cover2.jpg";
import cover3 from "../../assets/blogCovers/cover4.jpg";
import cover4 from "../../assets/blogCovers/cover5.jpg";
import { motion } from "framer-motion";

const fadeUp = {
  offScreen: {
    opacity: 0,
    y: 45,
  },
  onScreen: {
    y: 0,
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
    },
  },
};

const newsFeed = [
  {
    id: 1,
    date: "May",
    className:
      "flex flex-wrap justify-center lap:justify-between items-center lap:px-12 py-10 max-w-6xl mx-auto",
    image: {
      english: cover4,
      spanish: cover4,
      chinese: cover4,
    },
    title: {
      english: "Preamble",
      spanish: "Preámbulo",
      chinese: "序言",
    },
    mediumLink: {
      english: "https://medium.com/@oddplanet.official/preamble-1dcad5590ef5",
      spanish:
        "https://medium.com/@oddplanet.official/pre%C3%A1mbulo-28acbdea94a5",
      chinese:
        "https://medium.com/@oddplanet.official/%E5%BA%8F%E8%A8%80-8181beabf901",
    },
    bodyText: {
      english:
        "We’re in 2222, a criminal organization has found a way of crossbreeding aliens and zombies, and created a new species called Zoliens.",
      spanish:
        "Estamos en 2222, una organización criminal ha encontrado la forma de cruzar alienígenas y zombis, y ha creado una nueva especie llamada Zoliens.",
      chinese:
        "我们在2222年，一个犯罪组织找到了一种将外星人和僵尸杂交的方法，并创造了一个新的物种，叫做Zoliens。",
    },
  },
  // {
  //   id: 2,
  //   date: "May",
  //   className:
  //     "flex flex-wrap flex-row-reverse justify-center lap:justify-between items-center lap:px-12 py-10 max-w-6xl mx-auto",
  //   image: {
  //     english: Tunnel,
  //     spanish: Tunnel,
  //     chinese: Tunnel,
  //   },
  //   title: {
  //     english: "LOREM IPSUM SIN DOLOR EMET CHANGE THIS MF",
  //     spanish: "LOREM IPSUM SIN DOLOR EMET CHANGE THIS MF",
  //     chinese: "LOREM IPSUM SIN DOLOR EMET CHANGE THIS MF",
  //   },
  //   bodyText: {
  //     english:
  //       " Lorem ipsum sin dolor emet change this mf Lorem ipsum sin dolor emet change this mf Lorem ipsum sin dolor emet change this mf Lorem ipsum sin dolor emet change this mf Lorem ipsum sin dolor emet change this mf",
  //     spanish:
  //       " Lorem ipsum sin dolor emet change this mf Lorem ipsum sin dolor",
  //     chinese:
  //       "Lorem ipsum sin dolor emet change this mf Lorem ipsum sin dolor",
  //   },
  // },
  // {
  //   id: 3,
  //   date: "May",
  //   className:
  //     "flex flex-wrap justify-center lap:justify-between items-center lap:px-12 py-10 max-w-6xl mx-auto",
  //   image: {
  //     english: Timer,
  //     spanish: Timer,
  //     chinese: Timer,
  //   },
  //   title: {
  //     english: "LOREM IPSUM SIN DOLOR EMET CHANGE THIS MF",
  //     spanish: "LOREM IPSUM SIN DOLOR EMET CHANGE THIS MF",
  //     chinese: "LOREM IPSUM SIN DOLOR EMET CHANGE THIS MF",
  //   },
  //   bodyText: {
  //     english:
  //       " Lorem ipsum sin dolor emet change this mf Lorem ipsum sin dolor emet change this mf Lorem ipsum sin dolor emet change this mf Lorem ipsum sin dolor emet change this mf Lorem ipsum sin dolor emet change this mf",
  //     spanish:
  //       " Lorem ipsum sin dolor emet change this mf Lorem ipsum sin dolor",
  //     chinese:
  //       "Lorem ipsum sin dolor emet change this mf Lorem ipsum sin dolor",
  //   },
  // },
];

const Blog = () => {
  return (
    <div className=" bg-gradient-to-b from-[#260e64] to-[#010026] text-white">
      {/* TOP TITLE + BG IMAGE */}
      <div className="bg-blog h-[600px] bg-center bg-cover flex items-center">
        <p className="text-[70px] shadowText  font-medium leading-none pl-10">
          OBEAST <br />
          NEWS
        </p>
      </div>

      {/* NEWS SECTION */}
      {newsFeed.map((article) => (
        <motion.div
          variants={fadeUp}
          initial="offScreen"
          whileInView="onScreen"
          viewport={{ once: true, amount: 0.1 }}
          key={article.id}
          className={article.className}
        >
          <div className="w-[95%] px-4 tab:px-0 tab:w-[600px] lap:ml-4 pb-8 lap:pb-0">
            <div className="border-l-[1px] border-dashed border-white pl-6">
              <p className="text-[22px] pb-2 text-[#30fff8] font-medium">
                {article.date}
              </p>
              <a
                href={article.mediumLink.english}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={article.image.english}
                  alt="oBeast-news"
                  className="h-[400px] pb-4"
                />
              </a>

              <p className="text-[20px] tab:text-[30px] font-bold shadowText leading-tight pb-2">
                {article.title.english}
              </p>
              <p className="font-thin">{article.bodyText.english}</p>
            </div>
          </div>

          <div className="flex flex-col tab:flex-row lap:flex-col gap-10 lap:gap-4">
            <div className="w-[90%] tab:w-[280px] ml-2 tab:ml-4 ">
              <div className="border-l-[1px] border-dashed border-white pl-6">
                <a
                  href={article.mediumLink.spanish}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={article.image.spanish}
                    alt="oBeast-news"
                    className="h-[200px] pb-4"
                  />
                </a>

                <p className="text-[16px] font-bold shadowText leading-tight pb-2">
                  {article.title.spanish}
                </p>
                <p className="text-[12px] font-thin">
                  {article.bodyText.spanish}
                </p>
              </div>
            </div>
            <div className="w-[90%] tab:w-[280px] ml-2 tab:ml-4 ">
              <div className="border-l-[1px] border-dashed border-white pl-6">
                <a
                  href={article.mediumLink.chinese}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={article.image.chinese}
                    alt="oBeast-news"
                    className="h-[200px] pb-4"
                  />
                </a>

                <p className="text-[16px] font-bold shadowText leading-tight pb-2">
                  {article.title.chinese}
                </p>
                <p className="text-[12px] font-thin">
                  {article.bodyText.chinese}
                </p>
              </div>
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default Blog;
