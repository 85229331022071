import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./routes/home/home";
import ScrollToTop from "./components/scrollToTop/scrollToTop";
import Header from "./components/header/header";
import Token from "./routes/token/token";
import Blog from "./routes/Blog/blog";
import Footer from "./components/footer/footer";

function App() {
  return (
    <div className="font-Mulish">
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="token" element={<Token />} />
          <Route exact path="blog" element={<Blog />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
