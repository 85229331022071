import React, { useContext } from "react";
import { AppContext } from "../../context/app.context";
import { ReactComponent as Muted } from "../../assets/muted.svg";
import { ReactComponent as Unmuted } from "../../assets/unmuted.svg";
import HomeVideo from "../../assets/videos/trailer.mp4";
import chart from "../../assets/tokenChart.png";
import govern from "../../assets/tokenGovern.png";
import shop from "../../assets/tokenShop.png";
import shoot from "../../assets/tokenShoot.png";
import rewards from "../../assets/tokenRewards.png";
import coins from "../../assets/tokenCoins.png";
import coins2 from "../../assets/tokenCoins2.png";
import Supporters from "../../components/supporters/supporters";
import tokenVideo from "../../assets/videos/tokenVideo.mp4";

const Token = () => {
  const { isMuted, setMuted } = useContext(AppContext);

  const soundHandler = () => {
    setMuted(!isMuted);
  };
  return (
    <div className="text-white">
      {/* <div
        onClick={soundHandler}
        className="absolute bottom-6 right-8 z-10 h-12 w-12 hover:bg-[#520990] hover:bg-opacity-30 duration-[200ms] flex justify-center items-center rounded-md cursor-pointer"
      >
        {isMuted ? (
          <Muted className="h-7 w-7" />
        ) : (
          <Unmuted className="h-7 w-7" />
        )}
      </div> */}

      <div className="h-screen relative">
        {/* VIDEO BG */}
        <video
          loop
          muted
          autoPlay
          playsInline
          src={tokenVideo}
          className="object-cover w-full h-full"
          type="video/mp4"
        />
        <div className="absolute inset-x-0 h-full flex justify-center items-center gap-6 tab:gap-12 bottom-0 mb-12">
          {/* <button className="bg-[#0E0C1D] w-[150px] h-[50px] rounded-lg">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://odd-planet.gitbook.io/oddplanet/"
            >
              Litepaper
            </a>
          </button> */}
          <button className="bg-[#0E0C1D] opacity-90 w-[150px] h-[50px] rounded-lg cursor-not-allowed">
            Audit
          </button>
        </div>
      </div>

      <div className="pb-10 bg-gradient-to-b from-[#260e64] to-[#010026] flex justify-center ">
        <div className="flex flex-col items-center text-center">
          <p className="font-bold shadowText text-[40px] pb-8 pt-24">
            WHAT IS $OBEAST TOKEN ?
          </p>
          <p className="text-[14px] tab:text-[20px] px-8 max-w-4xl pb-10">
            $OBEAST is a governance token of the beasts, by the soldiers, and
            for the spaces in the OBeasts game metaverse, brought to you by your
            own OBEASTS team.
          </p>

          <video
            loop
            muted={isMuted}
            autoPlay
            playsInline
            src={HomeVideo}
            className="w-[95%] tab:w-[70%] mx-auto"
            type="video/mp4"
          />

          <div className="pb-44">
            <div className="flex flex-col tab:flex-row gap-6 justify-center items-center py-6 ">
              <img
                src={shoot}
                alt="oddplanet"
                className="w-[95%] tab:w-[34%]"
              />
              <img
                src={rewards}
                alt="oddplanet"
                className="w-[95%] tab:w-[34%]"
              />
            </div>
            <div className="flex flex-col tab:flex-row gap-6 justify-center items-center">
              <img
                src={govern}
                alt="oddplanet"
                className="w-[95%] tab:w-[34%]"
              />
              <img src={shop} alt="oddplanet" className="w-[95%] tab:w-[34%]" />
            </div>
          </div>

          <div className="w-full bg-token bg-center bg-cover flex flex-col justify-center items-center relative">
            <p className="font-bold shadowText text-[40px] pt-10 ">
              $OBEAST TOKENOMICS
            </p>
            <img src={chart} alt="tokenomics" className="w-[70%] z-20" />
            <img
              src={coins}
              alt="tokenomics"
              className="absolute bottom-0 left-0 tab:left-8 h-[60%]"
            />
            <img
              src={coins2}
              alt="tokenomics"
              className="absolute bottom-0 right-0 h-[30%] tab:h-[40%]"
            />
          </div>

          <Supporters />
        </div>
      </div>
    </div>
  );
};

export default Token;
//bg-gradient-to-b from-[#260e64] to-[#010026]
