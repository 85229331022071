import React from "react";
import logo from "../../assets/oba.png";
import { ReactComponent as Twitter } from "../../assets/icons/socialMedia/twitter.svg";
import { ReactComponent as Gitbook } from "../../assets/icons/socialMedia/gitbook.svg";
import { ReactComponent as Telegram } from "../../assets/icons/socialMedia/telegram.svg";
import { ReactComponent as Youtube } from "../../assets/icons/socialMedia/youtube.svg";
import { ReactComponent as Instagram } from "../../assets/icons/socialMedia/instagram3.svg";
import { ReactComponent as Tiktok } from "../../assets/icons/socialMedia/tiktok.svg";
import { ReactComponent as Medium } from "../../assets/icons/socialMedia/medium.svg";
// import { ReactComponent as Facebook } from "../../assets/icons/socialMedia/facebook.svg";
// import { ReactComponent as Twitch } from "../../assets/icons/socialMedia/twitch.svg";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const navigateToken = () => {
    navigate("/token");
  };

  const navigateBlog = () => {
    navigate("/blog");
  };
  return (
    <div className="relative  bg-gradient-to-b from-[#140D26] to-[#1A0E3C]">
      <div className=" pb-16  px-10 flex flex-col tab:flex-row justify-between lap:justify-start max-w-[1480px] mx-auto">
        <div className="pt-10">
          <img src={logo} alt="odd-planet" className="w-56" />
          <div className="text-[#babaff] text-[18px] pt-4  w-[300px] tab:border-r-[1px] tab:border-gray-600">
            <div className="space-y-2">
              <p
                onClick={navigateToken}
                className="cursor-pointer hover:text-white duration-500"
              >
                Token
              </p>
              <p
                onClick={navigateBlog}
                className="cursor-pointer hover:text-white duration-500"
              >
                Blog
              </p>
              <div className="">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://medium.com/@oddplanet.official"
                  className="hover:text-white duration-500 "
                >
                  Docs
                </a>
              </div>
            </div>

            {/* <p className="hover:text-white duration-500">Faq</p> */}
            <div className="flex gap-6 pt-4">
              <a
                href="https://twitter.com/Obeasts_"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter className="h-6 w-6 hover:scale-105 duration-200" />
              </a>

              <a
                href="https://odd-planet.gitbook.io/oddplanet/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Gitbook className="h-7 w-7" />
              </a>

              <Telegram className="h-6 w-6 cursor-not-allowed" />
            </div>
          </div>
        </div>

        <div className="pt-14 tab:px-10 lap:px-20">
          <p className="text-[30px] text-white shadowText pb-6">Follow us on</p>

          <div className="flex flex-col lap:flex-row">
            <div className="space-y-4 text-[16px]">
              <a
                href="https://twitter.com/Obeasts_"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-4 text-[#babaff]"
              >
                <Twitter className="h-8 w-8" />
                <p className="hover:text-white duration-500">
                  Twitter | @Obeasts_
                </p>
              </a>

              {/* <a
                href="https://www.tiktok.com/@oddplanet.official"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-4 text-[#babaff]"
              > */}
              <div className="flex items-center gap-4 text-[#babaff]">
                <Telegram className="h-8 w-8" />
                <p className="hover:text-white duration-500">Telegram</p>
              </div>
              {/* </a> */}

              {/* <a
                href="https://www.instagram.com/oddplanet.official/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-4 text-[#babaff]"
              > */}
              <div className="flex items-center gap-4 text-[#babaff]">
                <Medium className="h-8 w-8 " />
                <p className="hover:text-white duration-500">
                  Medium | OBeasts
                </p>
              </div>

              {/* </a> */}
            </div>

            {/* <div className="space-y-4 pt-4 lap:pt-0 lap:pl-20 text-[16px]">
              <a
                href="https://www.twitch.tv/oddplanet_"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-4 text-[#babaff]"
              >
                <Twitch className="h-8 w-8" />
                <p className="hover:text-white duration-500">
                  Twitch | Odd Planet
                </p>
              </a>

              <a
                href="https://www.facebook.com/profile.php?id=100092194367491&mibextid=ZbWKwL
              "
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-4 text-[#babaff]"
              >
                <Facebook className="h-8 w-8" />
                <p className="hover:text-white duration-500">
                  Facebook | @oddplanet.official
                </p>
              </a>
            </div> */}
          </div>
        </div>

        <p className="absolute bottom-4 left-1/2 -translate-x-1/2 text-[#babaff] font-extralight  text-[12px] tab:text-[16px] text-center">
          OBeasts ©️ 2024, All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
