import React from "react";
import { motion } from "framer-motion";
import { ReactComponent as Twitter } from "../../assets/icons/socialMedia/Twitter2.svg";
import Supporters from "../supporters/supporters";

const fadeUp = {
  offScreen: {
    opacity: 0,
    y: 45,
  },
  onScreen: {
    y: 0,
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
    },
  },
};

const cardItems = [
  {
    id: 1,
    image: "bg-venue",
    title: "Preamble",
    text: "We’re in 2222, a criminal organization has found a way of crossbreeding aliens and zombies, and created a new species called Zoliens.",
  },
  {
    id: 2,
    image: "bg-tunnel",
    title: "序言",
    text: "我们在2222年，一个犯罪组织找到了一种将外星人和僵尸杂交的方法，并创造了一个新的物种，叫做Zoliens。",
  },
  {
    id: 3,
    image: "bg-timer",
    title: "Preámbulo",
    text: "Estamos en 2222, una organización criminal ha encontrado la forma de cruzar alienígenas y zombis, y ha creado una nueva especie llamada Zoliens.",
  },
];

const Cards = () => {
  return (
    <motion.div
      variants={fadeUp}
      initial="offScreen"
      whileInView="onScreen"
      viewport={{ once: true, amount: 0.3 }}
      className="flex flex-col lap:flex-row items-center justify-center gap-6"
    >
      {cardItems.map((item) => (
        <div
          key={item.id}
          className="h-[300px] w-[95%] tab:w-[90%] lap:h-[300px] lap:w-[300px]  mon:h-[400px] mon:w-[400px] hoverUp lap:mt-12 rounded-xl border-[0.5px] border-gray-600 "
        >
          <div
            className={`bg-center bg-cover ${item.image} h-[72%] lap:h-[68%] mon:h-[72%] rounded-xl`}
          />
          <div className="h-[28%]  lap:h-[32%] mon:h-[28%] bg-[#1F124A] rounded-bl-xl rounded-br-xl text-white   flex flex-col justify-center  px-6 space-y-2 border-t-[1px] border-gray-600">
            <p className="text-[13px] lap:text-[14px] mon:text-[18px] font-bold shadowText ">
              {item.title}
            </p>
            <p className="text-[10px] lap:text-[12px] mon:text-[14px] text-[#babaff]">
              {item.text}
            </p>
          </div>
        </div>
      ))}
    </motion.div>
  );
};

const Bottom = () => {
  return (
    <div className=" bg-gradient-to-b from-[#260e64] to-[#010026] flex justify-center relative  text-white">
      <div className="bg-[#0E0C1D] w-full h-[0.25px] absolute top-0 z-20 fadeBox " />
      <div className="bg-[#0E0C1D] w-full h-[0.25px] absolute bottom-0 z-20 fadeBox " />

      <div className="">
        <p className="text-[50px] pb-8 tab:pb-0 font-extrabold shadowText text-center pt-16">
          LATEST OBEAST NEWS
        </p>
        <Cards />
        <motion.div
          variants={fadeUp}
          initial="offScreen"
          whileInView="onScreen"
          viewport={{ once: true, amount: 0.3 }}
          className="py-10 lap:py-0 w-[95%] tab:w-[90%] lap:w-[93%]  mon:w-[100%] lap:h-[270px] mon:h-[300px] rounded-lg bg-stars bg-center bg-cover mt-6 lap:mt-10 mx-auto border-[1px] border-gray-600 flex flex-col justify-center px-6 tab:px-12"
        >
          <p className="shadowText text-[30px] font-bold text-center tab:text-start">
            TWEET ABOUT US
          </p>
          <p className="shadowText text-[14px] max-w-md mt-4 mx-auto tab:mx-0 text-center tab:text-start">
            Want free $OBEAST tokens airdropped ? Share our OBEAST tweet with
            your friends and partners to let them know about our upcoming launch
            !
          </p>
          <a
            href="https://twitter.com/intent/tweet?text=Im entering the $OBEAST adventure and Im ready to take part in the most entertaining web3 game ever @Obeasts_ here I come !"
            target="_blank"
            rel="noopener noreferrer"
            className="w-[200px] h-[35px] rounded-lg bg-[#4b11af] bg-opacity-80 mt-6 hover:bg-opacity-90 flex gap-2 justify-center items-center border-[1px] border-gray-600 twitterHoverUp mx-auto tab:mx-0"
          >
            Tweet About Us <Twitter className="h-4 w-4 mt-[2px]" />
          </a>
        </motion.div>

        <Supporters />
      </div>
    </div>
  );
};

export default Bottom;
