import React from "react";
import Marquee from "react-fast-marquee";
import weapon1 from "../../assets/oddWeapons/1.png";
import weapon2 from "../../assets/oddWeapons/2.png";
import weapon3 from "../../assets/oddWeapons/3.png";
import weapon4 from "../../assets/oddWeapons/4.png";
import weapon5 from "../../assets/oddWeapons/5.png";
import weapon6 from "../../assets/oddWeapons/6.png";
import weapon7 from "../../assets/oddWeapons/7.png";
import weapon8 from "../../assets/oddWeapons/8.png";
import weapon9 from "../../assets/oddWeapons/9.png";
import weapon10 from "../../assets/oddWeapons/10.png";
import weapon11 from "../../assets/oddWeapons/11.png";
import weapon12 from "../../assets/oddWeapons/12.png";
import weapon13 from "../../assets/oddWeapons/13.png";
import weapon14 from "../../assets/oddWeapons/14.png";
import weapon15 from "../../assets/oddWeapons/15.png";

const firstRow = [
  { id: 1, image: weapon1 },
  { id: 2, image: weapon2 },
  { id: 3, image: weapon3 },
  { id: 4, image: weapon4 },
  { id: 5, image: weapon5 },
  { id: 6, image: weapon6 },
  { id: 7, image: weapon7 },
  { id: 8, image: weapon8 },
  { id: 9, image: weapon9 },
  { id: 10, image: weapon10 },
  { id: 11, image: weapon11 },
  { id: 12, image: weapon12 },
  { id: 13, image: weapon13 },
  { id: 14, image: weapon14 },
  { id: 15, image: weapon15 },
];

const Row1 = () => {
  return (
    <div className="flex">
      {firstRow.map((weapon) => {
        return (
          <div className="px-4 tab:px-6 lap:px-8">
            <img
              src={weapon.image}
              alt="oddWeapon"
              className="object-cover w-44 h-44 rounded-2xl "
            />
          </div>
        );
      })}
    </div>
  );
};

const Weapons = () => {
  return (
    <div className="space-y-8 py-8 relative z-20">
      <Marquee gradient={false} speed={100}>
        <Row1 />
      </Marquee>
      <Marquee gradient={false} speed={100} direction="right">
        <Row1 />
      </Marquee>
    </div>
  );
};

export default Weapons;
