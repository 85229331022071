import React, { useState } from "react";
import { ReactComponent as Socials } from "../../assets/icons/headerIcons/socials.svg";
import { ReactComponent as Arrow } from "../../assets/icons/headerIcons/dropDownArrow.svg";
import { ReactComponent as Twitter } from "../../assets/icons/socialMedia/twitter.svg";
import { ReactComponent as Gitbook } from "../../assets/icons/socialMedia/gitbook.svg";
import { ReactComponent as Telegram } from "../../assets/icons/socialMedia/telegram.svg";
import { ReactComponent as Youtube } from "../../assets/icons/socialMedia/youtube.svg";
import { ReactComponent as Instagram } from "../../assets/icons/socialMedia/instagram.svg";
import { ReactComponent as Tiktok } from "../../assets/icons/socialMedia/tiktok.svg";
import { ReactComponent as Facebook } from "../../assets/icons/socialMedia/facebook.svg";
import { ReactComponent as Twitch } from "../../assets/icons/socialMedia/twitch.svg";
import { ReactComponent as Medium } from "../../assets/icons/socialMedia/medium.svg";

const socialMedia = [
  {
    id: 1,
    icon: Twitter,
    link: "https://twitter.com/Obeasts_",
    className: "mb-3 w-6 h-6 cursor-pointer ",
  },
  // {
  //   id: 2,
  //   icon: Gitbook,
  //   link: "https://odd-planet.gitbook.io/oddplanet/",
  //   className: "mb-3 w-6 h-6 cursor-pointer ",
  // },
  {
    id: 3,
    icon: Telegram,
    // link: "", UNCOMMENT TO ADD LINK
    className: "mb-3 w-6 h-6 cursor-not-allowed",
  },
  // {
  //   id: 4,
  //   icon: Youtube,
  //   link: "https://www.youtube.com/channel/UC0_vMsTw1h3rmy0uUDcSD0Q",
  //   className: "mb-3 w-6 h-6 cursor-pointer ",
  // },
  // {
  //   id: 5,
  //   icon: Tiktok,
  //   link: "https://www.tiktok.com/@oddplanet.official",
  //   className: "mb-3 w-6 h-6 cursor-pointer ",
  // },
  // {
  //   id: 6,
  //   icon: Instagram,
  //   link: "https://twitter.com/Obeasts_",
  //   className: "mb-3 w-6 h-6 cursor-pointer ",
  // },
  // {
  //   id: 7,
  //   icon: Twitch,
  //   link: "https://www.twitch.tv/oddplanet_",
  //   className: "mb-3 w-6 h-6 cursor-pointer ",
  // },
  // {
  //   id: 8,
  //   icon: Facebook,
  //   link: "https://www.facebook.com/profile.php?id=100092194367491&mibextid=ZbWKwL",
  //   className: "mb-3 w-6 h-6 cursor-pointer ",
  // },
  {
    id: 9,
    icon: Medium,
    link: "https://medium.com/@oddplanet.official",
    className: "mb-3 w-6 h-6 cursor-pointer rounded-sm",
  },
];

const Dropdown = () => {
  const [isShown, setShown] = useState(false);

  return (
    <div
      onMouseLeave={() => setShown(false)}
      onMouseEnter={() => setShown(true)}
      className="group"
    >
      <div className="h-[32px] w-[55px] rounded-lg border-[1px] border-[#babaff] flex justify-center items-center shadow-[0_0_10px_rgba(76,51,233,1)] hover:shadow-[0_0_20px_rgba(76,51,233,1)] bg-[#0E0C1D] bg-opacity-80 group-hover:bg-[#0E0C1D]   relative z-20 duration-300">
        <Socials className="h-5 w-5 " />
        <Arrow className="ml-[5px] w-2 h-2 group-hover:rotate-[-90deg] duration-500" />
      </div>
      <div
        className={
          isShown
            ? "w-[55px]  border-x-[1px] border-b-[1px] border-[#babaff] rounded-b-lg absolute top-0 mt-[47px] shadow-[0_0_10px_rgba(76,51,233,1)] bg-[#0E0C1D] bg-opacity-30 backdrop-blur-xl flex justify-center pt-4 "
            : "hidden"
        }
      >
        <div>
          {socialMedia.map((item) => (
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              <item.icon key={item.id} className={item.className} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
