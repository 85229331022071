import React from "react";
import soldier1 from "../../assets/oBeastSoldiers.png";
import { motion } from "framer-motion";
import Weapons from "../marquees/weapons";

const fadeUp = {
  offScreen: {
    opacity: 0,
    y: 45,
  },
  onScreen: {
    y: 0,
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
    },
  },
};

const Characters = () => {
  return (
    <div className="bg-subtle bg-center bg-cover relative overflow-hidden text-white ">
      <div className="bg-[#0E0C1D] w-full h-[0.25px] absolute top-0 z-30 fadeBox" />
      <div className="bg-[#0E0C1D] w-full h-[0.25px] absolute bottom-0 z-10 fadeBox" />
      <p className="text-[50px] pb-6 font-extrabold shadowText text-center pt-20">
        SPACE ARTILLERY
      </p>
      <p className="shadowText text-center mx-auto pb-10 max-w-5xl text-[17px] px-4">
        OBeast Weaponry is vital to defend oneself from an imminent danger.
        Weapons vary on the nature of the enemy, their power level, and
        skillset. Grab yourself on OBeastNFT Day a bunch of armaments to max out
        your bounty outcome. P.S : $OBEAST presale investors get an OBeast
        Weapon airdrop each
      </p>
      <motion.div
        variants={fadeUp}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true, amount: 0.3 }}
        className="w-full tab:w-[95%]  bg-transparent backdrop-blur-md border-[1px] border-gray-600 relative overflow-hidden rounded-xl mx-auto mt-8 mb-32"
      >
        <div className="bg-[#0E0C1D] w-full h-[0.25px] absolute bottom-0 z-10 fadeBox2" />
        <Weapons />
      </motion.div>

      <p className="text-[50px]  font-extrabold shadowText text-center pb-6">
        OBEAST SOLDIERS
      </p>
      <p className="shadowText text-center mx-auto pb-20 max-w-5xl text-[17px] px-4">
        All OBeast adventures and tasks start with basic characters, but along
        the leveling up, you would need tougher soldiers to complete in-game
        $OBEAST tasks. Grab yourself on OBeastNFT day some golden-diamond ranked
        soldiers to complete the tasks easier and get the $OBEAST bonuses faster
      </p>
      <motion.img
        variants={fadeUp}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true, amount: 0.3 }}
        src={soldier1}
        alt="oBeastSoldier"
        className=" mx-auto "
      />
    </div>
  );
};

export default Characters;
