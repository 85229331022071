import React from "react";
import { ReactComponent as Arrow } from "../../assets/arrow.svg";
import { Link } from "react-scroll";
import HomeVideo from "../../assets/videos/homeVideo.mp4";

const Landing = () => {
  return (
    <div className="h-screen ">
      <video
        loop
        muted
        autoPlay
        playsInline
        src={HomeVideo}
        className="object-cover w-full h-full"
        type="video/mp4"
      />

      <div className="bg-[#0E0C1D] w-full h-[0.25px] absolute bottom-0 z-10 fadeBox " />
      <div className="flex justify-center items-center">
        <Link
          to="mid"
          smooth={true}
          duration={1250}
          spy={true}
          exact="true"
          offset={-60}
          className="absolute bottom-6 border-[1px] border-[#babaff] flex justify-center items-center z-10 rounded-full h-12 w-12 cursor-pointer bg-[#babaff] bg-opacity-10 hover:bg-[#babaff] hover:bg-opacity-20 shadow-[0_0_10px_rgba(76,51,233,1)] hover:shadow-[0_0_22px_rgba(76,51,233,1)] duration-[200ms] animate-bounce"
        >
          <Arrow className="h-10 w-10 mt-[26px] ml-[13px]" />
        </Link>
      </div>
    </div>
  );
};

export default Landing;
