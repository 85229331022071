import React from "react";
import Landing from "../../components/landing/landing";
import Games from "../../components/games/games";
import Sneakpeek from "../../components/sneakpeek/sneakpeek";
import Characters from "../../components/characters/characters";
import Bottom from "../../components/bottom/bottom";
import Footer from "../../components/footer/footer";

const Home = () => {
  return (
    <div className="bg-[#080619] ">
      <Landing />
      {/* <Sneakpeek /> */}
      <Games />
      <Characters />
      <Bottom />
    </div>
  );
};

export default Home;
