import React from "react";
import { Tilt } from "react-tilt";
import { motion } from "framer-motion";
import oBeastNfts from "../../assets/nft.jpg";
import oBeastGames from "../../assets/games.jpg";
import oBeastToken from "../../assets/token.jpg";

const fadeAni = {
  offScreen: {
    opacity: 0,
  },
  onScreen: {
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
    },
  },
};

const cardItems = [
  {
    id: 1,
    image: oBeastNfts,
    title: "OBeast NFTS",
    text: "Vital to get access to different weaponry to defend yourself during your space travel",
  },
  {
    id: 2,
    image: oBeastToken,
    title: "OBeast Token",
    text: "Access multi metaverse tasks and in-game rewards by holding $OBEAST. Presale $OBEAST hodlers benefit are explained in Token page.",
  },
  {
    id: 3,
    image: oBeastGames,
    title: "OBeast Games",
    text: "Obeast will cover a range of web3 games fully lucrative for both the token and nft hodlers. Gaming partners set to be announced for within end of Q4 2023.",
  },
];

const Cards = () => {
  return (
    <div className="flex flex-wrap justify-center gap-8 mon:gap-14">
      {cardItems.map((cardItem) => {
        return (
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{
              delay: cardItem.id * 0.2,
              type: "spring",
              duration: 0.5,
              ease: "easeOut",
            }}
          >
            <div
              className="w-[300px] h-[420px] tab:h-[410px]  mon:w-[375px] mon:h-[550px] bg-[#4c33e9] bg-opacity-50 backdrop-blur-lg rounded-lg flex flex-col items-center border-[1px] border-[#2a2169] fadeBox  fo
            nt-bold text-[#ddd]"
            >
              <Tilt
                className="flex justify-center items-center "
                options={{
                  max: 45,
                  scale: 1,
                  speed: 450,
                }}
              >
                <img
                  src={cardItem.image}
                  alt="obeast-nfts"
                  className="lap:h-[270px] mon:h-[350px] w-[93%] mt-4 rounded-lg"
                />
              </Tilt>
              <p className="pt-3 mon:pt-6 text-[18px] font-extrabold">
                {cardItem.title}
              </p>
              <p className="px-4 text-[14px] mon:text-[18px] text-center pt-2 text-gray-400">
                {cardItem.text}
              </p>
            </div>
          </motion.div>
        );
      })}
    </div>
  );
};

const Games = () => {
  return (
    <div
      id="mid"
      className="bg-wall pb-20 bg-center bg-cover flex flex-col justify-center items-center text-[17px] text-white  relative overflow-hidden"
    >
      <div className="bg-[#0E0C1D] w-full h-[0.25px] absolute top-0 z-20 fadeBox " />

      <motion.div
        variants={fadeAni}
        initial="offScreen"
        whileInView="onScreen"
        viewport={{ once: true, amount: 0.3 }}
        className=" pt-24 pb-20 text-center"
      >
        <p className="text-[50px] pb-6 font-extrabold shadowText px-6">
          OBEAST, THE FATE OF UNIVERSES
        </p>
        <p className="shadowText max-w-3xl px-4 tab:px-10 lap:max-w-5xl">
          Once Upon a time, a massive space attack happened on Universe-8 Space
          adventurers then sailed space to save Obeast, the center link of all
          universes. Along the way, they have to face space intruders and
          unknown dangers uncommon to the human race, to seek freedom and gain
          space notoriety and wealth once again. Are you joining the adventure,
          Odder ?
        </p>
      </motion.div>
      <Cards />
    </div>
  );
};

export default Games;
