import { createContext, useState } from "react";

export const AppContext = createContext();

export const WebAppProvider = ({ children }) => {
  const [isMuted, setMuted] = useState(true);

  const value = {
    isMuted,
    setMuted,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
